var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showMenu
        ? [
            _c("ul", { staticClass: "nav" }, [
              _c(
                "li",
                {
                  class: {
                    selected:
                      _vm.currentPath.indexOf("/setting/userSetting/plan") > -1
                        ? true
                        : false
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("SoftwareVersion")) + " ")]
              )
            ])
          ]
        : [
            _c(
              "p",
              { staticClass: "back", on: { click: _vm.goback } },
              [_c("a-icon", { attrs: { type: "left" } }), _vm._v("Back")],
              1
            )
          ],
      _c("router-view")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }