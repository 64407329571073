<template>
  <div>
    <template v-if="showMenu">
      <ul class="nav">
        <!-- <li
          :class="{
            selected:
              currentPath == '/setting/userSetting/userProfile' ? true : false,
          }"
        >
          <router-link to="/setting/userSetting/userProfile">
            {{ $t("UserInformation") }}</router-link
          >
        </li> -->
        <li
          :class="{
            selected:
              currentPath.indexOf('/setting/userSetting/plan') > -1
                ? true
                : false,
          }"
        >
          {{ $t("SoftwareVersion") }}
          <!-- <router-link to="/setting/userSetting/plan">{{
            $t("SoftwareVersion")
          }}</router-link> -->
        </li>
      </ul>
    </template>
    <template v-else>
      <p class="back" @click="goback"><a-icon type="left" />Back</p>
    </template>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/sales/salesReps/campaignDetail") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      console.log(to, from);
    },
  },
  computed: {
    currentPath: {
      get() {
        return this.$route.path;
      },
    },
  },
  i18n: {
    messages: {
      CN: {
        UserInformation: "用户信息",
        SoftwareVersion: "订阅",
      },
      US: {
        UserInformation: "User Information",
        SoftwareVersion: "Plan",
      },
      AR: {
        UserInformation: "معلومات المستخدم",
        SoftwareVersion: "اشترك",
      },
    },
  },
  created() {
    //this.showMenu = this.showMenu2();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    showMenu2() {
      if (this.$route.path == "/sales/salesReps/dialogue") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/createCampaign") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/campaignDetail") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="less">
.nav {
  width: 100%;
  padding-top: 7px;
  padding-left: 30px;
  border-top: #d1d1d1 solid 1px;
  li {
    height: 42px;
    line-height: 42px;
    padding: 0 12px;
    margin-right: 88px;
    display: inline-block;
    font-weight: bold;
    a {
    }
    color: #000;
    font-size: 16px;
    border-bottom: #fff 2px solid;
  }
  .selected {
    border-bottom: #fff 2px solid;
    // border-bottom: #0e756a 2px solid;
    a {
      font-weight: bold;
    }
  }
  background: #ffffff;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
}
.nav,
.back {
  left: 254px;
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 200;
}
.back {
  border-top: #d1d1d1 solid 1px;
  color: #0e756a;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  background: #fff;
}
</style>
